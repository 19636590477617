import { defineStore } from 'pinia';
import { store } from '/@/store';
import { WebSocketClient } from '/@/utils/lib/websocket';
import { useMessage } from '/@/hooks/web/useMessage';
import { router } from '/@/router';
import { h } from 'vue';
import { useUserStoreWithOut } from './user';
import { Message } from '/@/utils/lib/core';
import { usePhoneStoreWithOut } from './phone';

interface SocketState {
  socket: WebSocketClient | null;
  receiveCb: Array<(data: Message) => void>;
}

const { createMessageNotify } = useMessage();
export const useSocketStore = defineStore({
  id: 'app-socket',
  state: (): SocketState => ({
    socket: null,
    receiveCb: [],
  }),
  getters: {
    getSocket(): WebSocketClient {
      return this.socket as WebSocketClient;
    },
  },
  actions: {
    init() {
      const phoneStore = usePhoneStoreWithOut();
      const host = location.host.match('localhost') ? '192.168.12.146:11883' : location.host;
      const socket = new WebSocketClient(
        `${location.protocol.includes('https') ? 'wss' : 'ws'}://${host}/ws`,
      );
      const { createMessage } = useMessage();
      this.socket = socket;
      socket.connect().then(() => {
        this.login();
        socket!.receive((data) => {
          switch (data.event) {
            case 'needLogin':
              this.login();
              break;
            case 'message':
              if (data.payload.currentEvent) {
                switch (data.payload.currentEvent) {
                  case 'CHANNEL_ANSWER':
                    if (data.payload.direction === 'outbound' && data.payload.channelType === 'B') {
                      phoneStore.enterCall();
                    }
                    break;
                  case 'CHANNEL_HANGUP':
                    phoneStore.clear();
                    if (
                      data.payload.hangupReason &&
                      data.payload.hangupReason !== 'NORMAL_CLEARING'
                    ) {
                      createMessage.error(data.payload.hangup + '：' + data.payload.hangupReason);
                    }
                    break;
                }
              } else {
                this.receiveCb.forEach((cb) => cb(data));
                this.createMessage(data.payload);
              }
              break;
          }
        });
      });
    },
    login() {
      const userStore = useUserStoreWithOut();
      if (userStore.getToken) {
        this.socket!.send({
          event: 'login',
          payload: {
            appId: 2,
            groupId: userStore.getUserInfo.deptId || 'default',
            userId: userStore.getUserInfo.id,
          },
        });
      }
    },
    createMessage(payload: { content: string; type: 10 | 20 | 30 | 40 | 50 | 100 }) {
      let url = '';
      switch (payload.type) {
        case 10:
          url = '/messageCenter/notify';
          break;
        case 20:
          url = '/messageCenter/message/10';
          break;
        case 30:
          url = '/workbench/index';
          break;
        case 40:
          url = '/workbench/index';
          break;
        case 100:
          url = '/messageCenter/message/20';
          break;
      }
      if (url) {
        const res = payload.content.match(/\[([^}]+)\]/g);
        if (res) {
          res.forEach((i) => {
            payload.content = payload.content.replace(i, `<span class="primary-color">${i}</span>`);
          });
        }
        createMessageNotify({
          description: h('div', {
            innerHTML: payload.content,
          }),
          click: () => {
            router.push(url);
          },
        });
      }
    },
    receive(cb: (data: Message) => void) {
      this.receiveCb.push(cb);
    },
  },
});

// Need to be used outside the setup
export function useSocketStoreWithOut() {
  return useSocketStore(store);
}
