import { RouterMap } from '../../types';
import { getDataSourceConfigList } from '/@/api/sd/b2b';
import { getOrderReminderCount } from '/@/api/sd/orderCommon';

export const routeMap: RouterMap = new Map();

routeMap.set('/workOrder/follow/manualOrder', {
  name: 'ManualOrder',
  meta: {
    nav: [
      { id: '0', title: '下手工单' },
      { id: '1', title: '批量导入' },
      { id: '2', title: '下单异常' },
    ],
  },
});
routeMap.set('/workOrder/follow/guarantee', {
  name: 'Guarantee',
  meta: {
    nav: [
      { id: '10', title: '待审核' },
      { id: '30', title: '已审核' },
      { id: '20', title: '已驳回待修改' },
    ],
  },
});

routeMap.set('/workOrder/follow/partsSend', {
  name: 'PartsSend',
  meta: {
    nav: [
      { id: '110', title: '待发货' },
      { id: '120', title: '发货待全部签收' },
      { id: '130', title: '全部签收' },
      // { id: '140', title: '审核驳回' },
    ],
  },
});
routeMap.set('/workOrder/follow/partsRecycle', {
  name: 'PartsRecycle',
  meta: {
    nav: [
      { id: '220', title: '待返件' },
      { id: '230', title: '待全部签收' },
      { id: '240', title: '全部签收' },
      { id: '250', title: '签收异常' },
    ],
  },
});

routeMap.set('/workOrder/follow/serviceKeFu', {
  name: 'ServiceKeFu',
  meta: {
    nav: [
      { id: '1', title: '处理中' },
      { id: '2', title: '已完成' },
      { id: '4', title: '已取消' },
      { id: '3', title: '已退单' },
      { id: '0', title: '所有' },
      { id: '5', title: '投诉' },
      { id: '6', title: '催单' },
    ],
  },
});
routeMap.set('/workOrder/abnormal/dispose', {
  name: 'AbnormalDispose',
  meta: {
    nav: [
      { id: '0', title: '重单异常' },
      { id: '1', title: '其它异常' },
      { id: '2', title: '已处理' },
    ],
  },
});
routeMap.set('/workOrder/abnormal/noService', {
  name: 'NoService',
});

routeMap.set('/workOrder/abnormal/sBuyApprove', {
  name: 'SBuyApprove',
  meta: {
    nav: [
      { id: '40', title: '待客服审核' },
      { id: '50', title: '客服驳回' },
      { id: '60', title: '无需钉钉客服已通过' },
      { id: '70', title: '待登记钉钉' },
      { id: '80', title: '钉钉审核通过' },
      { id: '90', title: '钉钉审核驳回' },
    ],
  },
});
routeMap.set('/workOrder/abnormal/backParts', {
  name: 'AbnormalBackParts',
  meta: {
    nav: [
      { id: '260', title: '待处理' },
      { id: '270', title: '已处理' },
    ],
  },
});
routeMap.set('/workOrder/abnormal/exceptionKeFu', {
  name: 'ExceptionKeFu',
  meta: {
    nav: [
      {
        id: '6',
        title: '催单',
        sizeApi: () => {
          return getOrderReminderCount(110);
        },
      },
      { id: '1', title: '未完成', default: true },
      { id: '2', title: '已完工' },
      { id: '3', title: '已退单/已取消' },
      { id: '0', title: '所有' },
      { id: '5', title: '投诉' },
      { id: '7', title: '审单退回' },
      { id: '8', title: '求助' },
    ],
  },
});
routeMap.set('/workOrder/customerComplaint/manage', {
  name: 'CustomerComplaintManage',
  meta: {
    nav: [
      { id: '1', title: '未完成' },
      { id: '2', title: '已完工' },
      { id: '3', title: '已退单/已取消' },
      { id: '0', title: '所有' },
    ],
  },
});

routeMap.set('/workOrder/assignService/artificial', {
  name: 'Artificial',
  meta: {
    nav: [
      {
        id: '6',
        title: '催单',
        sizeApi: () => {
          return getOrderReminderCount(20);
        },
      },
      { id: '1', title: '未派单', default: true },
      { id: '2', title: '已派单' },
      { id: '5', title: '投诉' },
      { id: '7', title: '审单退回' },
      { id: '8', title: '求助' },
    ],
  },
});

routeMap.set('/workOrder/assignService/cashback', {
  name: 'Cashback',
  meta: {
    nav: [
      { id: '50', title: '待付款' },
      {
        id: '10',
        title: '待补充信息',
        helpMessage:
          '待补充信息页展示所有返现申请中，需要补充完工信息的工单，当前页签中的数据可能与后方从返现申请进度视角归类的数据有所重叠',
      },
      { id: '30', title: '审核驳回' },
      { id: '20', title: '待审核' },
      { id: '60', title: '已付款' },
      { id: '70', title: '已撤销' },
      { id: '80', title: '已补充信息' },
      { id: '40', title: '暂时无法付款' },
    ],
  },
});
routeMap.set('/workOrder/sales/B2B-dispose', {
  name: 'B2BDispose',
  meta: {
    api: getDataSourceConfigList,
  },
});

routeMap.set('/workOrder/sales/configure', {
  name: 'Configure',
  meta: {
    nav: [
      { id: '10', title: '服务类型' },
      { id: '20', title: '客户产品' },
      { id: '30', title: '质保类型' },
    ],
  },
});

routeMap.set('/workOrder/sales/customerAccount', {
  name: 'CustomerAccount',
});
routeMap.set('/workOrder/sales/signed', {
  name: 'Signed',
  meta: {
    nav: [
      { id: '0', title: '待处理' },
      { id: '1', title: '已同意' },
      { id: '2', title: '已拒绝' },
    ],
  },
});
routeMap.set('/workOrder/sales/manage', {
  name: 'SalesOrder',
  meta: {
    nav: [
      { id: '1', title: '处理中' },
      { id: '2', title: '已完成' },
      { id: '4', title: '已取消' },
      { id: '3', title: '已退单' },
      { id: '0', title: '所有' },
      { id: '5', title: '投诉' },
      { id: '6', title: '催单' },
    ],
  },
});
routeMap.set('/workOrder/tg/partsApprove', {
  name: 'PartsApprove',
  meta: {
    nav: [
      { id: '10', title: '待审核' },
      { id: '30', title: '已驳回' },
      { id: '20', title: '已审核' },
    ],
  },
});
routeMap.set('/workOrder/tg/technician', {
  name: 'Technician',
  meta: {
    nav: [
      { id: '1', title: '未完成' },
      { id: '2', title: '已完工' },
      { id: '3', title: '已退单/已取消' },
      { id: '0', title: '所有' },
    ],
  },
});
routeMap.set('/workOrder/general/manager', {
  name: 'GeneralManager',
  meta: {
    nav: [
      {
        id: '6',
        title: '催单',
        sizeApi: () => {
          return getOrderReminderCount(50);
        },
      },
      { id: '1', title: '未完成', default: true },
      { id: '2', title: '已完工' },
      { id: '3', title: '已退单/已取消' },
      { id: '0', title: '所有' },
      { id: '5', title: '投诉' },
      { id: '7', title: '审单退回' },
    ],
  },
});
routeMap.set('/workOrder/visit/completeAudit', {
  name: 'CompleteAudit',
  meta: {
    nav: [
      { id: '1', title: '未客评' },
      { id: '2', title: '已客评' },
    ],
  },
});
routeMap.set('/workOrder/messenger/partsSign', {
  name: 'PartsSign',
  meta: {
    nav: [
      { id: '190', title: '待联系' },
      { id: '200', title: '签收异常' },
      { id: '210', title: '全部签收' },
    ],
  },
});
routeMap.set('/workOrder/messenger/manage', {
  name: 'MessengerManage',
  meta: {
    nav: [
      {
        id: '6',
        title: '催单',
        sizeApi: () => {
          return getOrderReminderCount(70);
        },
      },
      { id: '1', title: '未完工', default: true },
      { id: '2', title: '待返件' },
      { id: '3', title: '已完工' },
      { id: '5', title: '投诉' },
      { id: '7', title: '审单退回' },
      { id: '8', title: '求助' },
    ],
  },
});
routeMap.set('/workOrder/rule/sendOrders', {
  name: 'SignedPage',
  meta: {
    nav: [
      { id: '10', title: '自动派单' },
      { id: '20', title: '更新动态' },
    ],
  },
});

routeMap.set('/workOrder/rule/autoDispatch', {
  name: 'AutoDispatch',
});

routeMap.set('/workOrder/rule/visit', {
  name: 'VisitPage',
  meta: {
    nav: [
      { id: '10', title: '自动回访' },
      { id: '20', title: '更新动态' },
    ],
  },
});
routeMap.set('/workOrder/rule/examine', {
  name: 'ExaminePage',
});

const reminderOptions = [
  { id: '10', title: '待回复' },
  { id: '20', title: '待上门' },
  { id: '30', title: '待完工' },
  { id: '40', title: '已完成' },
  { id: '0', title: '所有' },
];
routeMap.set('/workOrder/follow/reminder', {
  name: 'FollowReminder',
  meta: {
    nav: reminderOptions,
  },
});

routeMap.set('/workOrder/abnormal/reminder', {
  name: 'AbnormalReminder',
  meta: {
    nav: reminderOptions,
  },
});

routeMap.set('/workOrder/sales/reminder', {
  name: 'SalesReminder',
  meta: {
    nav: reminderOptions,
  },
});

routeMap.set('/workOrder/assignService/reminder', {
  name: 'AssignServiceReminder',
  meta: {
    nav: reminderOptions,
  },
});

routeMap.set('/workOrder/messenger/reminder', {
  name: 'MessengerReminder',
  meta: {
    nav: reminderOptions,
  },
});

routeMap.set('/workOrder/customerComplaint/complaint', {
  name: 'Complaint',
  meta: {
    nav: [
      { id: '10', title: '待受理' },
      { id: '20', title: '待判责' },
      { id: '30', title: '待执行' },
      { id: '1', title: '待跟进' },
      { id: '40', title: '已完成' },
      { id: '50', title: '申诉待处理' },
      { id: '60', title: '申诉已完成' },
      { id: '0', title: '所有' },
    ],
  },
});

routeMap.set('/workOrder/customerComplaint/chargeback', {
  name: 'ComplaintChargeback',
});

routeMap.set('/workOrder/abnormal/expenseApply', {
  name: 'ExpenseApply',
  meta: {
    nav: [
      { id: '10', title: '待审核' },
      { id: '30', title: '已生效申请' },
      { id: '20', title: '钉钉审核中申请' },
      { id: '40', title: '已驳回申请' },
      { id: '50', title: '已撤销申请' },
    ],
  },
});

routeMap.set('/workOrder/abnormal/reviewDocuments', {
  name: 'ReviewDocuments',
  meta: {
    nav: [
      { id: '10', title: '费用申请审核' },
      { id: '20', title: '保内保外审核' },
      { id: '30', title: '自购配件审核' },
      { id: '40', title: '退单审核' },
      { id: '50', title: '返现凭证审核' },
      { id: '60', title: '返件快递费审核' },
    ],
  },
});

routeMap.set('/workOrder/abnormal/exceptionHandling', {
  name: 'ExceptionHandling',
  meta: {
    nav: [
      // { id: '10', title: '重单管理' },
      // { id: '20', title: '挂起工单' },
      { id: '30', title: '催单管理' },
      { id: '40', title: '无客服单' },
      { id: '50', title: '异常数据' },
    ],
  },
});

routeMap.set('/workOrder/general/praiseView', {
  name: 'GeneralPraiseView',
});

routeMap.set('/workOrder/follow/praiseView', {
  name: 'FollowPraiseView',
  meta: {
    nav: [
      { id: '0', title: '审核中' },
      { id: '1', title: '审核通过' },
      { id: '2', title: '审核驳回' },
      { id: '3', title: '无效好评' },
      { id: '10', title: '所有' },
    ],
  },
});

routeMap.set('/workOrder/abnormal/praiseView', {
  name: 'AbnormalPraiseView',
  meta: {
    nav: [
      { id: '10', title: '审核中' },
      { id: '20', title: '自动审核通过' },
      { id: '30', title: '待处理驳回' },
      { id: '40', title: '已处理驳回' },
      { id: '50', title: '无效好评' },
      { id: '0', title: '所有' },
    ],
  },
});

routeMap.set('/workOrder/sales/praiseView', {
  name: 'SalesPraiseView',
});

routeMap.set('/workOrder/messenger/praiseView', {
  name: 'MessengerPraiseView',
});


routeMap.set('/workOrder/sales/partsSend', {
  name: 'SalesPartsSend',
  meta: {
    nav: [
      { id: '110', title: '待发货' },
      { id: '120', title: '发货待全部签收' },
      { id: '130', title: '全部签收' },
      // { id: '140', title: '审核驳回' },
    ],
  },
});

routeMap.set('/workOrder/sales/partsRecycle', {
  name: 'SalesPartsRecycle',
  meta: {
    nav: [
      { id: '220', title: '待返件' },
      { id: '230', title: '待全部签收' },
      { id: '240', title: '全部签收' },
      { id: '250', title: '签收异常' },
    ],
  },
});


routeMap.set('/workOrder/abnormal/warranty', {
  name: 'Warranty',
  meta: {
    nav: [
      { id: '20', title: '待处理' },
      { id: '30', title: '待执行' },
      { id: '40', title: '已完成' },
      { id: '0', title: '所有' },
    ],
  },
});

// routeMap.set('/workOrder/abnormal/Auditing',{
//   name:'Auditing',
//   meta: {
//     nav: [
//       { id: '0', title: '费用申请审核' },
//       { id: '10', title: '保内保外审核' },
//       { id: '20', title: '自购配件审核' },
//       { id: '30', title: '退单审核' },
//     ],
//   },
// })
