import {
  CallTransferParams,
  DailCallParams,
  GetCallAgentResultModel,
  GetCallDetailsParams,
  GetCallDetailsResultModel,
  GetCurrentDialPageParams,
  GetCurrentDialPageResultModel,
  GetCustomerInfoForCallResultModel,
  GetDialPageParams,
  GetDialPageResultModel,
  GetInnerUserListForCallParams,
  GetInnerUserListForCallResultModel,
  GetListByDialLogIdsParams,
  GetListByDialLogIdsResultModel,
  GetMessageForDetailResultModel,
  GetMessagePageForCallParams,
  GetMessagePageForCallResultModel,
  GetOrderListForCallParams,
  GetOrderListForCallResultModel,
  GetOrderListForScreenParams,
  GetOrderListForScreenResultModel,
  GetServiceInfoForCallResultModel,
  MarkLogParams,
  PopOnScreenParams,
  PopOnScreenResultModel,
  DialBackParams,
  UpdateAgentParams,
  QueueLoginParams,
  UpdateInnerUserBaseParams,
  UpdateInnerUserBaseResultModel,
  DialIntentionParams,
  CallNewParams,
  TransferCallParams,
} from './model';
import { sdHttp, mdHttp, defHttp, imHttp, callHttp } from '/@/utils/http/axios';

export enum Api {
  Unregister = '/unregister',
  UpdateAgent = '/queue/agent/update',
  DailCall = '/call/dail',
  GetCallAgent = '/call/getAgent/',
  PopOnScreen = '/call/popOnScreen',
  GetOrderListForScreen = '/call/getOrderListForScreen',
  GetInnerUserListForCall = '/innerUser/getInnerUserListForCall',
  MarkLog = '/markLog',
  GetListByDialLogIds = '/markLog/getListByDialLogIds',
  GetTransferList = '/sys/call/transfer/getTransferList',
  CallTransfer = '/call/transfer',
  GetCurrentDialPage = '/dialLog/getCurrentDialPage',
  GetCallDetails = '/dialLog/getDetails/',
  GetOrderListForCall = '/dialLog/getOrderListForDetails',
  GetDialPage = '/dialLog/getDialPage',
  GetCustomerInfoForCall = '/dialLog/getCustomerInfo/',
  GetServiceInfoForCall = '/dialLog/getServiceInfo/',
  GetMessageForDetail = '/dialLog/getMessageForDetail/',
  GetMessagePageForCall = '/dialLog/getMessagePage',
  DialBack = '/call/dialBack',
  QueueLogin = '/queue/login',
  QuquqLogout = '/queue/logout',
  Hold = '/hold',
  UnHold = '/unHold',
  UpdateInnerUserBase = '/innerUser/updateInnerUserBase',
  DialIntention = '/call/dialIntention',
  GetSpPhone = '/call/getSpPhone',
}

const isHttps = location.protocol.includes('https');
// 注册分机号前调用的注销接口
export function unregister(extension: string) {
  setTimeout(() => {
    unregister(extension);
  }, 300000);
  return defHttp.get<GetCallAgentResultModel>(
    {
      url: Api.Unregister,
      params: { extension },
    },
    {
      hideLoading: true,
      apiUrl: isHttps ? 'https://sbc.kklgo.com/extension' : 'http://192.168.12.180:9003/extension',
      decrypt: true, // 不加密
    },
  );
}

// 更新队列中坐席的状态
export function updateAgent(data: UpdateAgentParams) {
  return imHttp.post<boolean>(
    {
      url: Api.UpdateAgent,
      data,
    },
    { hideLoading: true },
  );
}

// 坐席签入
export function queueLogin(data: QueueLoginParams) {
  return imHttp.post<boolean>(
    {
      url: Api.QueueLogin,
      data,
    },
    { hideLoading: true },
  );
}

// 坐席签出
export function ququqLogout(data: QueueLoginParams) {
  return imHttp.post<boolean>(
    {
      url: Api.QuquqLogout,
      data,
    },
    { hideLoading: true },
  );
}

// 保持电话
export function hold(channelId: string) {
  return imHttp.post<boolean>({
    url: Api.Hold,
    data: { channelId },
  });
}

// 取消保持电话
export function unHold(channelId: string) {
  return imHttp.post<boolean>({
    url: Api.UnHold,
    data: { channelId },
  });
}

// 拨号
export function call(data: DailCallParams) {
  return sdHttp.post<boolean>({
    url: Api.DailCall,
    data,
  });
}

// 获取登录用户的坐席信息
export function getCallAgent(userId: number) {
  return mdHttp.get<GetCallAgentResultModel>(
    {
      url: Api.GetCallAgent + userId,
    },
    { hideLoading: true },
  );
}

// 来电弹屏,
export function popOnScreen(data: PopOnScreenParams) {
  return sdHttp.post<PopOnScreenResultModel>({
    url: Api.PopOnScreen,
    data,
  });
}

// 来点弹屏的工单信息
export function getOrderListForScreen(data: GetOrderListForScreenParams) {
  return sdHttp.post<GetOrderListForScreenResultModel[]>({
    url: Api.GetOrderListForScreen,
    data,
  });
}

// 获取呼叫通讯录
export function getInnerUserListForCall(params: GetInnerUserListForCallParams) {
  return mdHttp.get<GetInnerUserListForCallResultModel>({
    url: Api.GetInnerUserListForCall,
    params,
  });
}

// 新增备注/标记
export function markLog(data: MarkLogParams) {
  return sdHttp.post<boolean>({
    url: Api.MarkLog,
    data,
  });
}

// 查看标记
export function getListByDialLogIds(params: GetListByDialLogIdsParams) {
  return sdHttp.get<GetListByDialLogIdsResultModel>({
    url: Api.GetListByDialLogIds,
    params,
  });
}

// 获取最近10条转接记录
export function getTransferList(callApplication: number) {
  return mdHttp.get<GetInnerUserListForCallResultModel['list']>({
    url: Api.GetTransferList,
    params: { callApplication },
  });
}

// 拨号转接
export function callTransfer(data: CallTransferParams) {
  return sdHttp.post<boolean>({
    url: Api.CallTransfer,
    data,
  });
}

// 当前登录用户查看通话记录,
export function getCurrentDialPage(params: GetCurrentDialPageParams) {
  return sdHttp.get<GetCurrentDialPageResultModel>({
    url: Api.GetCurrentDialPage,
    params,
  });
}

// 通话记录详情
export function getCallDetails(params: GetCallDetailsParams) {
  return sdHttp.get<GetCallDetailsResultModel>({
    url: Api.GetCallDetails + params.id + '/' + params.quarter,
  });
}

// 通话记录详情的工单列表,
export function getOrderListForCall(params: GetOrderListForCallParams) {
  return sdHttp.get<GetOrderListForCallResultModel[]>({
    url: Api.GetOrderListForCall,
    params,
  });
}

// 通话报表
export function getDialPage(params: GetDialPageParams) {
  return sdHttp.get<GetDialPageResultModel>({
    url: Api.GetDialPage,
    params,
  });
}

// 通话记录详情查看客户信息,
export function getCustomerInfoForCall(customerId: number) {
  return sdHttp.get<GetCustomerInfoForCallResultModel>({
    url: Api.GetCustomerInfoForCall + customerId,
  });
}

// 通话记录详情查看网点信息,
export function getServiceInfoForCall(servicePointId: number) {
  return sdHttp.get<GetServiceInfoForCallResultModel>({
    url: Api.GetServiceInfoForCall + servicePointId,
  });
}

// 呼叫详情获取短信记录,
export function getMessageForDetail(mobile: string) {
  return sdHttp.get<GetMessageForDetailResultModel[]>({
    url: Api.GetMessageForDetail + mobile,
  });
}

// 分页获取短信记录,
export function getMessagePageForCall(params: GetMessagePageForCallParams) {
  return sdHttp.get<GetMessagePageForCallResultModel>({
    url: Api.GetMessagePageForCall,
    params,
  });
}

// 回拨
export function dialBack(data: DialBackParams) {
  return sdHttp.post<boolean>({
    url: Api.DialBack,
    data,
  });
}

// 18.个人信息修改用户基本信息,
export function updateInnerUserBase(data: UpdateInnerUserBaseParams) {
  return mdHttp.put<UpdateInnerUserBaseResultModel>({
    url: Api.UpdateInnerUserBase,
    data,
  });
}

// 意向入驻拨号
export function dialIntention(data: DialIntentionParams) {
  return sdHttp.post<boolean>({
    url: Api.DialIntention,
    data,
  });
}

// 获取师傅手机号
export function getSpPhone(data: DailCallParams) {
  return sdHttp.post<string>({
    url: Api.GetSpPhone,
    data,
  });
}

// 拨打电话(新)
export async function callNew(params: CallNewParams) {
  return callHttp.get<string>({
    url: '/dial',
    params,
  });
}

// 查询电话归属地
export async function getTelInfo(params: { tel: string }) {
  return callHttp.get<string>({
    url: '/getTelInfo',
    params,
  });
}

// 电话转移
export async function transferCall(params: TransferCallParams) {
  return callHttp.get<null>({
    url: '/transfer',
    params,
  });
}

// 挂断
export async function hangup(params: { extension: string }) {
  return callHttp.get<null>({
    url: '/hangup',
    params,
  });
}
