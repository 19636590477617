import { RouterMap } from '../../types';

export const routeMap: RouterMap = new Map();

routeMap.set('/messageCenter/message', {
  name: 'Message',
  meta: {
    nav: [{ id: '10', title: '消息反馈' }, { id: '20', title: '工单异常' }],
  },
});

routeMap.set('/messageCenter/notify', {
  name: 'MessageNotify',
});
